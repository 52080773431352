import React from 'react';
import {Card, Button} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPrint} from "@fortawesome/free-solid-svg-icons";


export function GoodPractices() {

    const availablePresentation = [
        {
            name: "5S",
            fileName: "5s.html",
            text: "Méthode d'organisation et d'amélioration du travail en équipe.",
            image: "/data/static/img/good_practices/5s.png",
            enable: true
        },
        {
            name: "Le language Python 3",
            fileName: "definition_python.html",
            text: "Définition du language Python 3",
            image: "/data/static/img/good_practices/python.png",
            enable: true
        },
        {
            name: "Bonne pratiques Python 3",
            fileName: "good_practices_python.html",
            text: "Les bonne pratique à adopter pour Python 3",
            image: "/data/static/img/good_practices/python.png",
            enable: true
        },
        {
            name: "Tips & Tricks Python 3",
            fileName: "tips_and_tricks_python.html",
            text: "Quelque truc et astuce pour Python 3",
            image: "/data/static/img/good_practices/python.png",
            enable: true
        },
        {
            name: "Design pattern Python 3",
            fileName: "design_pattern_python.html",
            text: "Quelque design pattern en Python 3",
            image: "/data/static/img/good_practices/python.png",
            enable: true
        },
        {
            name: "SDLC",
            fileName: "software_development_life_cycle.html",
            text: "Cycle de vie d'une application.",
            image: "/data/static/img/good_practices/app_life_cycle.png",
            enable: false
        },
        {
            name: "KISS",
            fileName: "kiss.html",
            text: "Principe K.I.S.S.",
            image: "/data/static/img/good_practices/kiss.png",
            enable: false
        },
        {
            name: "SOLID",
            fileName: "solid.html",
            text: "Les principes S.O.L.I.D.",
            image: "/data/static/img/good_practices/solid.png",
            enable: false
        },
        {
            name: "Autres",
            fileName: "autres.html",
            text: "Autres principes ou idées.",
            image: "/data/static/img/good_practices/autres.png",
            enable: false
        },
    ]

    const openPresentation = (name: string) => {
        window.open(`/good_practices/presentation?name=${name}`, "Data", 'height=728,width=1018');
    }

    const openPresentationPdf = (name: string) => {
        window.open(`/good_practices/presentation?name=${name}&print-pdf`, "Data", 'height=728,width=1018');
    }

    return (
        <div className="flex flex-row flex-wrap justify-around h-fit">
            {availablePresentation.map((presentation, index) =>
                <div className="basis-1/5 pt-5" key={index}>
                    <Card className={`w-52 h-fit m-auto ${presentation.enable ? "" : "disable"}`}>
                        <div className="grow flex flex-col items-center">
                            <div
                                className="mb-3 p-2 rounded-full shadow-lg bg-white w-full h-24 flex justify-center"
                            >
                                <img
                                    alt="Logo de la présentation"
                                    src={presentation.image}
                                    className="object-cover h-full"
                                />
                            </div>
                            <div className="flex mb-1 w-full h-16 justify-center items-center">
                                <h5 className="text-xl font-medium text-gray-900 dark:text-white text-center">
                                    {presentation.name}
                                </h5>
                            </div>

                            <div className="flex mb-1 w-full h-16 justify-center items-center">
                                <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                                    {presentation.text}
                                </p>
                            </div>
                            <div className="mt-2 flex space-x-3">
                                <Button.Group>
                                    <Button
                                        color="blue"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            openPresentation(presentation.fileName);
                                        }}
                                        disabled={!presentation.enable}
                                    >
                                        <FontAwesomeIcon icon={faEye} className="h-6 w-6"/>
                                    </Button>
                                    <Button
                                        color="cyan"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            openPresentationPdf(presentation.fileName);
                                        }}
                                        disabled={!presentation.enable}
                                    >
                                        <FontAwesomeIcon icon={faPrint} className="h-6 w-6"/>
                                    </Button>
                                </Button.Group>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </div>
    );
}